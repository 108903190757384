<template>
	<b-modal id="concilig-exportar-monitoria-atendimento" title="Exportar Relatório - Monitorias Atendimento" size="md" @hide="reset">
		<div class="data-field">
			<label for="exportar-monitoria-atendimento-datainicio">Data início:</label>
			<input type="date" id="exportar-monitoria-atendimento-datainicio" v-model="dataInicio" />
		</div>
		<div class="data-field">
			<label for="exportar-monitoria-atendimento-datafim">Data fim:</label>
			<input type="date" id="exportar-monitoria-atendimento-datafim" v-model="dataFim" />
		</div>
		<div v-if="listaSetores" class="data-field">
			<label for="exportar-monitoria-atendimento-setorid">Setor:</label>
			<select id="exportar-monitoria-atendimento-setorid" v-model="setorId">
				<option :value="null">Todos</option>
				<option v-for="setor in listaSetores" :value="setor.Id">{{setor.Nome}}</option>
			</select>
		</div>
		<div v-if="listaUsuarios" class="data-field">
			<label for="exportar-monitoria-atendimento-usuarioid">Atendente:</label>
			<select id="exportar-monitoria-atendimento-usuarioid" v-model="usuarioId">
				<option :value="null">Todos</option>
				<option v-for="usuario in listaUsuarios" :value="usuario.Id">{{usuario.Nome}}</option>
			</select>
		</div>
		<template #modal-footer="{ cancel }">
			<b-button class="rounded-0" variant="light" @click="cancel">Cancelar</b-button>
			<b-button class="rounded-0" variant="success" :disabled="exportarRelatorioIsLoading || !dataInicio || !dataFim" @click="exportarRelatorio">
				<span v-if="exportarRelatorioIsLoading">
					<b-spinner small class="mr-1"></b-spinner>
					Processando...
				</span>
				<span v-else>Exportar</span>
			</b-button>
		</template>
	</b-modal>
</template>

<script>
	import axios from "axios";

	export default {
		name: "ConciligMonitoriasAtendimento",
		data() {
			return {
				dataInicio: null,
				dataFim: null,
				setorId: null,
				usuarioId: null,
				exportarRelatorioIsLoading: false,
				listaSetores: null,
				listaUsuarios: null
			};
		},
		watch: {
			setorId() {
				if (!this.setorId) {
					this.listaUsuarios = null;
					return;
				}
				axios.get(`api/setor/${this.setorId}/usuarios`).then(response => {
					this.listaUsuarios = response.data;
				});
			}
		},
		methods: {
			exportarRelatorio() {
				const form = {
					dataInicio: this.dataInicio,
					dataFim: this.dataFim,
					setorId: this.setorId,
					usuarioId: this.usuarioId
				};
				this.exportarRelatorioIsLoading = true;
				axios.get("api/consultas/monitoria-atendimento", {
					responseType: "arraybuffer",
					params: Object.assign({}, ...Object.keys(form).filter(key => form[key]).map(key => ({ [key]: form[key] })))
				}).then(response => {
					const link = document.createElement("a");
					link.href = window.URL.createObjectURL(new Blob([response.data]));
					link.download = `monitoria-atendimento-${Date.now()}.xlsx`;
					link.click();
					link.remove();
					this.exportarRelatorioIsLoading = false;
					this.$bvModal.hide("concilig-exportar-monitoria-atendimento");
				}).catch(() => {
					this.$bvToast.toast(`Não foi possível exportar o relatório no momento.`, {
						title: "Algo deu errado...",
						variant: "danger",
						solid: true
					});
					this.exportarRelatorioIsLoading = false;
				});
			},
			reset() {
				this.dataInicio = null;
				this.dataFim = null;
				this.setorId = null;
				this.usuarioId = null;
				this.exportarRelatorioIsLoading = false;
			}
		},
		created() {
			axios.get("api/setor/lista-basica").then(response => {
				this.listaSetores = response.data;
			})
		}
	}
</script>